import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import './../components/layout.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Seo from "../components/seo"
import { Row, Col, Image } from "react-bootstrap"

// imports van images
import SilenceSongAnnetteStenzel from "./../images/silence song, a film by anett stenzel/fp-silence song, a film by anett stenzel.jpg"
import postcards from "./../images/un entretien avec un chat, postcards/fp-un entretien avec un chat, postcards.jpg"

import foldingposters from "./../images/folding posters/fp-folding posters.jpg"
import borgfeldArchitecture from "./../images/borgfeld as an archisculpture, risograph/fp-borgfeld as an archisculpture, risograph.jpg"

import socialeSpel from "./../images/het sociale spel/newfp-het-sociale-spel.png"

import SilenceSongvar1 from "./../images/silence song variation 1/fp-silence song variation 1.jpg"
import heicafe from "./../images/heicafé/fp-heicafé.jpg"

import Vuil from "./../images/vuil, risograph/fp-vuil, risograph.jpg"
import KeepWarm from "./../images/keep yourself warm/fp-keep yourself warm.png"

import Pleasednt from "./../images/please don't/fp-please don't.jpg"
import SilenceSongCatalogue from "./../images/silence song, catalog/fp-silence song, catalog.jpg"

import Tunneltaal from "./../images/tunneltaal/fp-tunneltaal.jpg"

import Spaghettidag from "./../images/spaghettidag/fp-spaghettidag.jpg"
import SilenceSongVar2 from "./../images/silence song variation 2/fp-silence song variation 2.jpg"
import SilenceSongVar3 from "./../images/silence song variation 3/fp-silence song variation 3.jpg"

import illustrations from "./../images/illustrations/fp-illustrations.jpg"
import TheDarkGalleries from "./../images/the dark galleries/fp-the dark galleries.jpg"

import AboutBeauty from "./../images/about beauty, risograph/fp-about beauty, risograph.jpg"

import LastCents from "./../images/the last cents of europe/fp-the last cents of europe.jpg"
import Trump from "./../images/trump blew up his head/fp-trump blew up his head.jpg"

import FoldingLines from "./../images/folding lines/fp-folding lines.jpg"
import Workshops from "./../images/workshops/fp-workshops.jpg"

import LivingBlocks from "./../images/living blocks/livingBlocks-FP.png"

import FamilieDag from "./../images/familiedag/familiedag-FP.png"

import GhosnDos from "./../images/ghosndos/ghos&dhos-FP.png"

import limonchiello from "./../images/liemonchiello/limonchiello-FP.jpg"

const IndexPage = () => (
  <Layout>
    <Seo title="Manon Lambeens" />
      <Row className="text-center" >
      <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={SilenceSongVar3} fluid alt="Silence Song, by Annett Stenzel, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={LivingBlocks} fluid alt="Living Blocks, Manon Lambeens"/>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={12} s={12} xs={12} className="px-1 mt-2">
          <Link to="/Tunneltaal"><Image src={Tunneltaal} fluid style={{width: '100%'}} alt="Tunneltaal Henry Van de Velde, Manon Lambeens"/></Link>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={FamilieDag} fluid alt="Familiedag, Manon Lambeens"/>
        </Col>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          {/* <Link to="/Borgfeld-as-an-archisculpture"> */}
            <Image src={GhosnDos} fluid alt='ghos&dhos, Risograph, Manon Lambeens'/>
            {/* </Link> */}
        </Col>
      </Row>

      <Row>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={SilenceSongCatalogue} fluid alt="Silence Song By Annette Stenzel, Manon Lambeens"/>
        </Col>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={foldingposters} fluid alt="Folding Posters, Manon Lambeens"/>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={limonchiello} fluid alt="Limonchiello, Manon Lambeens"/>
        </Col>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Link to="/The-Dark-Galleries"><Image src={TheDarkGalleries} fluid alt="The Dark Galleries, Manon Lambeens"/></Link>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={12} s={12} xs={12} className="px-1 mt-2">
          <Link to="/Het-Sociale-Spel"><Image src={socialeSpel} fluid style={{width: '100%'}} alt="Het Sociale Spel, Manon Lambeens"/></Link>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={SilenceSongVar2} fluid alt="Silence Song, by Annett Stenzel, Manon Lambeens"/>
        </Col>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={SilenceSongvar1} fluid alt="Silence Song, A Film by Annette Stenzel, Manon Lambeens"/>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Link to="/Un-Entretien-Avec-Un-Chat"><Image src={postcards} fluid alt="Un Entretien Avec Un Chat, Manon Lambeens"/></Link>
        </Col>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={heicafe} fluid alt="Heicafé, poster, Manon Lambeens"/>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Link to="/Please-Dont"><Image src={Pleasednt} fluid alt="Please Don't, Manon Lambeens"/></Link>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="ps-1 pe-1 mt-2">
          <Image src={SilenceSongAnnetteStenzel} fluid alt="Silence Song, A Film By Annette Stenzel, Manon Lambeens"/>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={12} s={12} xs={12} className="px-1 mt-2">
          <Link to="/About-Beauty"><Image src={AboutBeauty} fluid style={{width: '100%'}} alt="About Beauty, Manon Lambeens"/></Link>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={Vuil} fluid alt="Vuil, Risograph, Manon Lambeens"/>
        </Col>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={illustrations} fluid alt="Illustrations By Manon Lambeens"/>
        </Col>
      </Row>

      <Row className="text-center p-0">
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={LastCents} fluid alt="The Last Cents Of Europe, Manon Lambeens"/>
        </Col>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={Spaghettidag} fluid alt="Spaghettidag, Manon Lambeens"/>
        </Col>
      </Row>

      {/* <Row className="text-center p-0">
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={FoldingLines} fluid alt="Folding Lines, Manon Lambeens"/>
        </Col>
        <Col md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Link to="Workshops"><Image src={Workshops} fluid alt="Workshops By Manon Lambeens"/></Link>
        </Col>
      </Row> */}
  </Layout>
)

export default IndexPage
